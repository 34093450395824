import {
  IContent,
  IFooter,
  IHeader,
  IPage,
  IPageMeta,
  IPageProps,
  IPageSEO,
  ISiteMeta,
} from '../types'
import Home, { getStaticProps as getProps } from './[[...all]]'

const defaultPage: IPage = {
  PageMeta: {} as IPageMeta,
  SiteMeta: {} as ISiteMeta,
  Header: {} as IHeader,
  Content: {} as IContent,
  Footer: {} as IFooter,
  SEO: { og: {} } as IPageSEO,
  isPreview: false,
  isDarkTheme: false,
  isPlayerEmbed: false,
}

export const getStaticProps = async () => {
  const { props, revalidate } = (await getProps({})) as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: any
    revalidate: number
  }
  defaultPage.Footer = props?.page?.Footer
  defaultPage.Header = props?.page?.Header

  return { props: { page: defaultPage }, revalidate }
}

export default function Custom404({
  page,
  baseUrl,
  googleTagManager,
  googleAnalytics,
}: IPageProps) {
  return (
    <Home
      page={page}
      notFound={true}
      baseUrl={baseUrl}
      googleTagManager={googleTagManager}
      googleAnalytics={googleAnalytics}
      login_page={''}
      bigBrotherRegistration={'on'}
      shouldTaboolaWaitForInterstitial='off'
    />
  )
}
